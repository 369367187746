.container {
    border: 1px;
    padding: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 10px;
}

.bold {
    font-weight: bold;
}

.title {
    font-weight: bold;
}

@media (max-width: 575.98px) {

    .container {
        margin: 100px 10px 0px;
        height: 50vh;
    }

    
}